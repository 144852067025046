define(function(require) {
    const MetricCardTemplate = require('./MetricCard.template.html');
    const CardView = require('components/legacy/Card.view');
    const _ = require('underscore');
    const CC = require('CC');
    require('./MetricCard.css');

    const MetricCardView = CardView.extend({
        template: _.template(MetricCardTemplate),

        defaults: function() {
            return {
                clientId: CC.utils.getCurrentClientId(),
                state: this.Card_State.LOADING,
                title: undefined,
                footer: undefined,
                label: 'metrics',
                size: 'sm',
                format: 'normal' // normal|compact
            };
        },

        events: {},

        validation: function() {
            return [
                {
                    name: 'metric',
                    required: true
                },
                {
                    name: 'entityType',
                    required: true
                }
            ];
        },

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);
            self.validateProperties();

            self.metrics = _.map(_.isArray(self.metric) ? self.metric : [self.metric], metric => {
                let formattedThreshold;
                let formattedValue = this.formatNumber(metric.value, 'standard');
                if (this.hasValue(metric.threshold)) {
                    formattedValue = this.formatNumber(metric.value, 'tiny');
                    formattedThreshold = this.formatNumber(metric.threshold, 'tiny');
                }
                const thresholdStateClass = this.getThresholdState(metric.value, metric.threshold);
                return {
                    value: formattedValue,
                    label: metric.label,
                    link: metric.link,
                    tooltip: metric.tooltip,
                    hasData: self.hasValue(metric.value),
                    showGraph: metric.showGraph,
                    formattedThreshold: formattedThreshold,
                    thresholdStateClass: thresholdStateClass,
                    suggestion:
                        thresholdStateClass == 'warning-level-two' && metric.excessThresholdSuggestion
                            ? `\n\n${metric.excessThresholdSuggestion}`
                            : ''
                };
            });

            if (this.hasValue(this.headerLinks)) {
                options.title = _.template(/*template*/ `
            <div class="metric-card-header">
                <div class="h18" data-weight="medium"><%- title %></div>
                <div class="btn-group card-overflow" role="group">
                    <i data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="lotacon lotacon-horizontal-overflow"/>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <% _.each(links, function(link) { %>
                            <li class="card-overflow__item" value="<%=link.name %>"><a class="h12" data-weight="regular" href="<%= link.href %>"><%=link.name%></a></li>
                        <% });%>
                    </ul>
                </div>
            </div>`)({
                    title: this.title,
                    links: this.headerLinks
                });
            }

            CardView.prototype.initialize.apply(self, [options]);
        },

        hasNoData: function() {
            const values = _.reduce(
                this.metrics,
                function(memo, num) {
                    // Make sure value is actually a number
                    return memo + +num.value;
                },
                0
            );
            return values === 0;
        },

        render: function() {
            const self = this;

            if (_.isNull(this.metric)) {
                return;
            }
            CardView.prototype.render.apply(self, arguments);

            if (self.state === self.Card_State.ERROR) {
                self.renderNoData(`Error loading ${self.label}. Refresh the page and try again.`);
                return self;
            }

            self.renderContent();

            return self;
        },

        /**
         *
         * @param {Number} number
         * @param {Text} abbreviation tiny|standard (default)
         * @param {Boolean} asText html by default, otherwise just text
         * @returns a formatted number
         */
        formatNumber: function(number, abbreviation, asText) {
            if (!this.hasValue(number)) {
                return number;
            }

            if (Math.abs(number) > 100000) {
                const friendlyNumber = CC.utils.friendly_number(number, 1, true, 100000, abbreviation);
                if (abbreviation === 'standard' || _.isUndefined(abbreviation)) {
                    const pieces = friendlyNumber.split(' ');
                    if (['thousand', 'million', 'billion', 'trillion'].indexOf(pieces[1]) !== -1) {
                        if (asText) {
                            return `${pieces[0]} ${pieces[1].capitalize()}`;
                        } else {
                            return `${pieces[0]}<span class="friendly-standard"> ${pieces[1]}</span>`;
                        }
                    }
                }
                return friendlyNumber;
            }
            return CC.converters.formatNumber(number);
        },

        renderMetric: function(el, number, abbreviation, threshold) {
            this.$(el).html(this.formatNumber(number, abbreviation, false) || '0');
            if (threshold) {
                this.$(el).append('/');
                this.$(el).append(this.formatNumber(threshold, abbreviation, false));
            }
        },

        renderContent: function() {
            const self = this;

            const viewOptions = {
                state: self.state,
                metrics: self.metrics,
                entityType: self.entityType,
                size: self.size,
                format: self.format
            };

            self.$('.card-body').html(this.template(viewOptions));
            return this;
        },

        getThresholdState: function(count, threshold) {
            if (count && threshold) {
                const percentage = (count / threshold) * 100;
                if (percentage < 100) {
                    if (percentage >= 90) {
                        return 'warning-level-one';
                    }
                } else {
                    return 'warning-level-two';
                }
            }
            return '';
        }
    });

    MetricCardView.extend = function(child) {
        const view = CardView.extend.apply(this, arguments);
        view.prototype.events = _.extend({}, this.prototype.events, child.events);
        view.prototype.defaults = function() {
            return _.extend({}, _.result(this.prototype, 'defaults'), _.result(child, 'defaults'));
        };

        const parentValidation = _.result(this.prototype, 'validation').slice();
        const childValidation = _.result(child, 'validation');
        _.each(
            childValidation,
            function(item) {
                const otherItem = _.findWhere(parentValidation, { name: item.name });
                if (otherItem) {
                    _.extend(otherItem, item);
                } else {
                    parentValidation.push(item);
                }
            },
            this
        );
        view.prototype.validation = function() {
            return parentValidation;
        };
        return view;
    };

    return MetricCardView;
});
