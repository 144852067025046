define(function(require) {
    const DataInTemplate = require('./DataIn.template.html');
    const BaseView = require('dcViews/Base.view');
    const CardView = require('components/legacy/Card.view');
    const _ = require('underscore');
    const CC = require('CC');
    const dc = require('dc');
    const d3 = dc.d3;
    const crossfilter = require('crossfilter2').default;
    const Backbone = require('backbone');
    const moment = require('moment');
    require('./DataIn.css');

    const DataInView = BaseView.extend({
        template: _.template(DataInTemplate),

        defaults: function() {
            return {
                clientId: CC.utils.getCurrentClientId()
            };
        },

        events: {},

        validation: [
            {
                name: 'model',
                required: true
            },
            {
                name: 'month',
                required: true
            },
            {
                name: 'state',
                required: true
            }
        ],

        initialize: function(options) {
            _.extend(this, this.defaults(), options);

            this.validateProperties();

            BaseView.prototype.initialize.apply(this, arguments);
        },

        render: function() {
            const viewOptions = {};

            this.$el.html(this.template(viewOptions));
            this.renderChart();

            return this;
        },

        renderChart: function() {
            const parseTime = d3.timeParse('%Y%m%d');

            const TAG_EVENTS = 'Tag Events';
            const SS_RECORDS = 'Server Side Records';

            let bcps = _.map(this.model.get('bcpCounts'), function(model) {
                return {
                    dataDate: parseTime(model.dataDate),
                    value: +model.count,
                    type: TAG_EVENTS
                };
            });
            bcps = this.padDatesInMonth(bcps, TAG_EVENTS);

            let ingestorRecords = _.map(this.model.get('ingestorRecords'), function(model) {
                return {
                    dataDate: parseTime(model.dataDate),
                    value: +model.count,
                    type: SS_RECORDS
                };
            });
            ingestorRecords = this.padDatesInMonth(ingestorRecords, SS_RECORDS);

            const collection = new Backbone.Collection(bcps.concat(ingestorRecords));

            const tooltip =
                'Tag Events: Total number of times Lotame tags were fired\n\n' +
                'Server Side Records: Total number of server-side records successfully imported';

            this.createAndRenderSubView('.data-in-chart', CardView, {
                cardStyle: 'terrene',
                title: `<span class="more-info" data-toggle="tooltip"  title="${tooltip}">Data In</span>`
            });
            this.$('[data-toggle="tooltip"]').tooltip();

            const xFilter = crossfilter(collection.models);
            const dimension = xFilter.dimension(function(d) {
                return d.get('dataDate');
            });
            const lastEntry = dimension.top(1);

            const tagGroup = dimension.group().reduce(
                function reduceAdd(p, v) {
                    if (v.get('type') === TAG_EVENTS) {
                        return p + v.get('value');
                    }
                    return p;
                },

                function reduceRemove(p, v) {
                    if (v.get('type') === TAG_EVENTS) {
                        return p - v.get('value');
                    }
                },

                function reduceInitial() {
                    return 0;
                }
            );

            const ssrGroup = dimension.group().reduce(
                function reduceAdd(p, v) {
                    if (v.get('type') === SS_RECORDS) {
                        return p + v.get('value');
                    }
                    return p;
                },

                function reduceRemove(p, v) {
                    if (v.get('type') === SS_RECORDS) {
                        return p - v.get('value');
                    }
                },

                function reduceInitial() {
                    return 0;
                }
            );

            if (this.state === 'loaded' && this.hasNoData(collection)) {
                this.state = 'empty';
            }
            this.prepElement('.data-in-chart .card-body', 'data-in', this.state);
            if (this.state === 'empty') {
                return;
            }

            const chart = dc.compositeChart('.data-in-filter', this.group);
            const width = this.$('.data-in-filter').width();
            const legendOffset = 300;

            const barChart = this.buildHistogram(chart, dimension, ssrGroup, {
                showTooltip: true,
                getTooltip: function(d) {
                    const tooltip = _.template(/* template */ `                    
                    <div><%- date %></div>
                    <div><strong><%- count %></strong></div>
                    <div><%- name %> </div>
                    `);
                    return tooltip({
                        name: SS_RECORDS,
                        count: CC.utils.friendly_number(d.data.value, 1, true, 1000, 'standard'),
                        date: d3.timeFormat('%B %e')(d.data.key)
                    });
                }
            })
                .group(ssrGroup, SS_RECORDS)
                .ordinalColors([this.convertColorVariableToHex('--bar-chart-color')])
                .hidableStacks(false)
                .centerBar(true)
                .useRightYAxis(true)
                .xAxisPadding(1)
                .title(() => undefined);

            const lineChart = this.buildLineChart(chart, dimension, tagGroup, {
                renderArea: true,
                renderDataPoints: true,
                groupName: TAG_EVENTS,
                showTooltip: true,
                lastDataDate: this.model.get('dateRange'),
                getTooltip: function(d) {
                    const tooltip = _.template(/* template */ `                    
                    <div><%- date %></div>
                    <div><strong><%- count %></strong></div>
                    <div><%- name %> </div>
                    `);
                    return tooltip({
                        name: TAG_EVENTS,
                        count: CC.utils.friendly_number(d.data.value, 1, true, 1000, 'standard'),
                        date: d3.timeFormat('%B %e')(d.data.key)
                    });
                }
            })
                .renderDataPoints({
                    radius: 3
                })
                .dotRadius(3)
                .xyTipsOn(true)
                .title(() => undefined)
                .clipPadding(0)
                .curve(d3.curveCardinal.tension(0.5))
                .ordinalColors([this.convertColorVariableToHex('--line-chart-color')])
                .xAxisPadding(1);

            chart
                .shareTitle(false)
                .width(width)
                .dimension(dimension)

                .renderHorizontalGridLines(true)
                .renderVerticalGridLines(true)
                .brushOn(false)
                .x(d3.scaleTime())
                .xUnits(d3.timeDays)
                .elasticY(true)
                .yAxisPadding('15%')
                .elasticX(true)
                .margins({
                    top: 45,
                    right: 80,
                    bottom: 50,
                    left: 60
                })
                .yAxisLabel('Total Count')
                .xAxisLabel(this.month)
                .compose([barChart, lineChart])
                .yAxisLabel(TAG_EVENTS)
                .rightYAxisLabel(SS_RECORDS)
                .controlsUseVisibility(false)
                .turnOnControls(false)
                .legend(
                    dc
                        .legend()
                        .x(width - legendOffset)
                        .y(5)
                        .itemHeight(12)
                        .horizontal(true)
                        .legendWidth(400)
                        // TOMA-2524: Don't set autoItemWidth or it breaks in Firefox
                        .itemWidth(100)
                        .autoItemWidth(false)
                );

            chart
                .yAxis()
                .tickFormat(function(v) {
                    return CC.utils.friendly_number(v, 1, true, 100000, 'tiny');
                })
                .ticks(3);
            chart
                .rightYAxis()
                .tickFormat(function(v) {
                    return CC.utils.friendly_number(v, 1, true, 100000, 'tiny');
                })
                .ticks(3);

            chart
                .xAxis()
                .ticks(d3.timeDay, 2)
                .tickFormat(data => {
                    // Workaround the xAxisPadding on the left side of the graph
                    const tickDate = moment(data);
                    const endDate = moment(lastEntry[0].get('dataDate'));

                    if (tickDate.isAfter(endDate, 'day')) {
                        return '';
                    }
                    return d3.timeFormat('%e')(data);
                });

            chart.on('renderlet', () => {
                if (this.$el) {
                    this.onRenderlet(chart, legendOffset);
                }
            });

            chart.on('preRender', () => {
                if (this.$el) {
                    this.$('.area').attr('fill', 'none');
                    const width = this.$('.data-in-filter').width();
                    barChart.gap(this.calculateGap(width - chart.margins().left - chart.margins().right, bcps.length));
                }
            });
            chart.on('preRedraw', () => {
                if (this.$el) {
                    // Reset the fill helper
                    this.$('.data-in-chart').addClass('initial-fill');
                }
            });
            chart.on('postRedraw', () => {
                if (this.$el) {
                    const newWidth = this.$('.data-in-filter').width();
                    chart.width(newWidth);
                    barChart.gap(
                        this.calculateGap(newWidth - chart.margins().left - chart.margins().right, bcps.length)
                    );

                    // Reset the fill helper
                    this.$('.data-in-chart').addClass('initial-fill');
                    chart.render();
                }
            });

            this.$(chart.anchor()).addClass('composite-chart');

            // Keep the line chart on top but reverse the order of the
            // legend so that Server Side records is on the same side
            // as its axis
            dc.override(chart, 'legendables', function() {
                const legendables = this._legendables();
                return legendables.reverse();
            });

            dc.renderAll();
        },

        onRenderlet: function(chart, legendOffset) {
            const gradientId = 'data-in__tag-events';
            const fill = `url(#${[gradientId]})`;
            if (this.$('.area').attr('fill') == fill) {
                return;
            }

            // Add a gradient under the line chart
            const color = this.convertColorVariableToHex('--color-primary');
            const lg = chart
                .svg()
                .selectAll('defs')
                .append('linearGradient')
                .attr('id', gradientId)
                .attr('x1', '0%')
                .attr('x2', '0%')
                .attr('y1', '0%')
                .attr('y2', '100%');
            lg.append('stop')
                .attr('offset', '0%')
                .style('stop-color', color)
                .style('stop-opacity', 0.2);
            lg.append('stop')
                .attr('offset', '30%')
                .style('stop-color', color)
                .style('stop-opacity', 0.1);
            lg.append('stop')
                .attr('offset', '100%')
                .style('stop-color', color)
                .style('stop-opacity', 0);

            this.$('.area').attr('fill', fill);

            // Remove the temporary class that prevents the area from being rendered
            // solidly until this renderlet can be run
            this.$('.data-in-chart').removeClass('initial-fill');

            // Flip around the right y axis label
            const transform = this.$('.yr-label').attr('transform');
            if (transform) {
                this.$('.yr-label').attr('transform', transform.replace('90', '-90'));
            }

            // DC doesn't recalculate the legend x on redraw
            const width = this.$('.data-in-filter').width();
            chart.legend().x(width - legendOffset);
            chart.legend().render();

            // Hack to give more space between the legend text and circle
            // because the gap is hard coded in the source
            const x = +this.$('.dc-legend-item text').attr('x');
            this.$('.dc-legend-item text').attr('x', x + 4);
        },

        /**
         * Work around the dc.js elasticX always automatically choosing bar width by
         * number of bars with no regard to how wide the bars get. We are using gap so that
         * the bars are always centered
         * @param {Number} width The width of main chart area (eg. not the axis)
         * @param {Number} dataLength Number of entries
         * @returns {Number}
         */
        calculateGap: function(width, dataLength) {
            let gap = 5;
            if (dataLength <= 10) {
                const targetWidth = width > 200 ? 25 : 15;
                gap = (width - dataLength * targetWidth) / (dataLength + 1);
            }

            return gap;
        },

        hasNoData: function(collection) {
            const values = collection.reduce(function(memo, num) {
                // Make sure value is actually a number
                return memo + +num.get('value');
            }, 0);
            return values === 0;
        }
    });

    return DataInView;
});
