define(function(require) {
    const CardTemplate = require('./Card.template.html');
    const BaseView = require('app/Base.view');
    const BasicModel = require('models/app/BasicModel');
    const _ = require('underscore');
    require('./Card.css');

    const CardView = BaseView.extend({
        cardTemplate: _.template(CardTemplate),

        Card_State: {
            LOADING: 'loading',
            LOADED: 'loaded',
            ERROR: 'error'
        },

        cardDefaults: function() {
            return {
                name: _.uniqueId('card_'),
                title: 'Card Title',
                footer: undefined,
                cardStyle: 'light',
                contentView: undefined, // Either extend Card.view OR pass in a contentView
                isEmpty: false
            };
        },

        events: {},

        initialize: function(options) {
            const self = this;

            const defaults = _.result(self, 'defaults', {});
            _.extend(self, self.cardDefaults(), defaults, options);

            this.model = new BasicModel({
                title: self.title,
                footer: self.footer
            });

            this.config = {
                '.card-header': {
                    observe: 'title',
                    updateMethod: 'html',
                    updateView: true,
                    visible: function(val, options) {
                        return this.hasValue(val);
                    }
                },
                '.card-footer': {
                    observe: 'footer',
                    updateMethod: 'html',
                    updateView: true,
                    visible: function(val, options) {
                        return this.hasValue(val);
                    }
                }
            };
        },

        /**
         * If Inheriting, be sure to call
         * CardView.prototype.render.apply(self, arguments);
         * and render your content to .content-body
         * @return {[type]} [description]
         */
        render: function() {
            const self = this;
            const viewOptions = {
                cardStyle: self.cardStyle,
                name: self.name,
                isEmpty: self.isEmpty
            };

            self.$el.html(this.cardTemplate(viewOptions));
            self.stickit(self.model, this.config);

            // If a content view is included, render it
            if (this.contentView) {
                self.assign('.card-body', self.contentView);
            }

            return self;
        },

        renderNoData: function(message) {
            this.$('.card-body').html(this.alertHolder);
            this.$('#alert-holder').addClass('text-center');
            this.renderAlert({
                alertType: 'default',
                message: message || 'Insufficient Data'
            });
        }
    });

    return CardView;
});
