define(function(require) {
    const DataOutTemplate = require('./DataOut.template.html');
    const BaseView = require('dcViews/Base.view');
    const CardView = require('components/legacy/Card.view');
    const _ = require('underscore');
    const CC = require('CC');
    const dc = require('dc');
    const d3 = dc.d3;
    const Backbone = require('backbone');
    const crossfilter = require('crossfilter2').default;
    const moment = require('moment');
    require('./DataOut.css');

    const DataOutView = BaseView.extend({
        template: _.template(DataOutTemplate),

        defaults: function() {
            return {
                height: 185
            };
        },

        events: {},

        validation: [
            {
                name: 'model',
                required: true
            },
            {
                name: 'month',
                required: true
            },
            {
                name: 'state',
                required: true
            }
        ],

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);

            self.validateProperties();

            BaseView.prototype.initialize.apply(this, arguments);
        },

        render: function() {
            const self = this;
            const viewOptions = {};

            self.$el.html(this.template(viewOptions));
            self.renderChart();

            return self;
        },

        renderChart: function() {
            const self = this;

            const tooltip = 'Total number of Audience Extraction API calls requested';
            self.createAndRenderSubView('.data-out-chart', CardView, {
                cardStyle: 'terrene',
                title: `<span class="more-info" data-toggle="tooltip" title="${tooltip}">Data Out</span>`
            });
            self.$('[data-toggle="tooltip"]').tooltip();

            const parseTime = d3.timeParse('%Y%m%d');

            const collection = new Backbone.Collection(
                _.map(self.model.get('extractionApiRequests'), function(model) {
                    return {
                        key: parseTime(model.dataDate),
                        value: +model.count,
                        type: 'Audience Extraction API Calls'
                    };
                })
            );
            const xFilter = crossfilter(collection.models);

            const dimension = xFilter.dimension(function(d) {
                return d.get('key');
            });
            const group = dimension.group().reduceSum(function(d) {
                return d.get('value');
            });

            if (self.state === 'loaded' && self.hasNoData(collection)) {
                self.state = 'empty';
            }
            self.prepElement('.data-out-chart .card-body', 'data-out', self.state);
            if (self.state === 'empty') {
                return;
            }
            const lastEntry = dimension.top(1);

            const width = self.$('.data-out-filter').width();
            const legendOffset = 220;
            const chart = self
                .buildHistogram('.data-out-filter', dimension, group, {
                    turnOnControls: false,
                    showTooltip: true,
                    getTooltip: function(d) {
                        const tooltip = _.template(/* template */ `                    
                        <div><%- date %></div>
                        <div><strong><%- count %></strong></div>
                        <div><%- name %> </div>
                        `);
                        return tooltip({
                            name: 'Calls',
                            count: CC.utils.friendly_number(d.data.value, 1, true, 1000, 'standard'),
                            date: d3.timeFormat('%B %e')(d.data.key)
                        });
                    }
                })
                .width(width)
                .group(group, 'Audience Extraction API Calls')
                .height(self.height)
                .x(d3.scaleTime())
                .xUnits(d3.timeDays)
                .centerBar(true)
                .xAxisPadding(1)
                .y(d3.scaleLinear())
                .renderHorizontalGridLines(true)
                .renderVerticalGridLines(true)
                .ordinalColors([self.convertColorVariableToHex('--bar-chart-color')])
                .yAxisLabel('Total Count')
                .yAxisPadding('15%')
                .xAxisLabel(this.month)
                .brushOn(false)
                .title(() => undefined)
                .legend(
                    dc
                        .legend()
                        .x(width - legendOffset)
                        .y(5)
                        .itemHeight(12)
                        .horizontal(true)
                        // TOMA-2524: Don't set autoItemWidth or it breaks in Firefox
                        .itemWidth(100)
                        .autoItemWidth(false)
                )
                .margins({
                    top: 25,
                    right: 20,
                    bottom: 50,
                    left: 80
                });

            chart
                .yAxis()
                .tickFormat(function(v) {
                    return CC.utils.friendly_number(v, 1, true, 100000, 'tiny');
                })
                .ticks(2);

            chart
                .xAxis()
                .ticks(d3.timeDay, 2)
                .tickFormat(data => {
                    // Workaround the xAxisPadding on the left side of the graph
                    const tickDate = moment(data);
                    const endDate = moment(lastEntry[0].get('key'));

                    if (tickDate.isAfter(endDate, 'day')) {
                        return '';
                    }
                    return d3.timeFormat('%e')(data);
                });

            chart.on('preRender', () => {
                if (this.$el) {
                    this.$('.area').attr('fill', 'none');
                    const width = this.$('.data-out-filter').width();
                    chart.gap(
                        this.calculateGap(width - chart.margins().left - chart.margins().right, collection.length)
                    );
                }
            });
            chart.on('preRedraw', () => {
                if (this.$el) {
                    const width = this.$('.data-out-filter').width();
                    chart.gap(
                        this.calculateGap(width - chart.margins().left - chart.margins().right, collection.length)
                    );
                    chart.render();
                }
            });

            chart.on('renderlet', () => {
                if (this.$el) {
                    // DC doesn't recalculate the legend x on redraw
                    const width = this.$('.data-out-filter').width();
                    chart.legend().x(width - legendOffset);
                    chart.legend().render();

                    // Hack to give more space between the legend text and circle
                    // because the gap is hard coded in the source
                    const x = +this.$('.dc-legend-item text').attr('x');
                    this.$('.dc-legend-item text').attr('x', x + 1);
                }
            });

            dc.renderAll();
        },

        /**
         * Work around the dc.js elasticX always automatically choosing bar width by
         * number of bars with no regard to how wide the bars get. We are using gap so that
         * the bars are always centered
         * @param {Number} width The width of main chart area (eg. not the axis)
         * @param {Number} dataLength Number of entries
         * @returns {Number}
         */
        calculateGap: function(width, dataLength) {
            let gap = 5;
            if (dataLength <= 10) {
                const targetWidth = width > 200 ? 25 : 15;
                gap = (width - dataLength * targetWidth) / (dataLength + 1);
            }

            return gap;
        },

        hasNoData: function(collection) {
            const values = collection.reduce(function(memo, num) {
                // Make sure value is actually a number
                return memo + +num.get('value');
            }, 0);
            return values === 0;
        }
    });

    return DataOutView;
});
