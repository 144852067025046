define(function(require) {
    const TrendChartTemplate = require('./TrendChart.template.html');
    const BaseDCView = require('dcViews/Base.view');
    const dc = require('dc');
    const d3 = dc.d3;
    const crossfilter = require('crossfilter2').default;
    const _ = require('underscore');
    const CC = require('CC');
    const moment = require('moment');

    const TrendChartView = BaseDCView.extend({
        template: _.template(TrendChartTemplate),

        defaults: function() {
            return {
                name: 'trend-chart',
                data: [],
                asSparkLine: true,
                groupName: '',
                showTooltip: true,
                sparkLineHeight: 50
            };
        },

        events: {},

        validation: [
            {
                name: 'field',
                required: true
            },
            {
                name: 'state',
                required: true
            }
        ],

        initialize: function(options) {
            _.extend(this, this.defaults(), options);

            this.validateProperties();

            if (/\s/g.test(this.name)) {
                throw new Error('name must not contain whitespace');
            }

            BaseDCView.prototype.initialize.apply(this, arguments);
        },

        render: function() {
            const self = this;
            const viewOptions = {};

            self.$el.html(this.template(viewOptions));

            const xFilter = crossfilter(self.data);

            if (self.state === 'loaded' && self.data.length <= 1) {
                self.state = 'empty';
            }
            self.prepElement('.trend-chart-main', self.name, self.state);

            if (self.state !== 'error') {
                if (this.asSparkLine) {
                    this.renderSparkLine(xFilter);
                } else {
                    this.renderLineChart(xFilter);
                }
                dc.renderAll();
            }

            return self;
        },

        renderLineChart: function(xFilter) {
            const width = this.$('.trend-chart-main').width();

            const parseTime = d3.timeParse('%Y%m%d');
            const dimension = xFilter.dimension(d => {
                return parseTime(d.get('dataDate'));
            });
            const group = dimension.group().reduceSum(d => {
                return +d.get(this.field);
            });
            const lastEntry = dimension.top(1);
            const chart = this.buildLineChart(`.${this.name}-filter`, dimension, group, {
                renderArea: true,
                renderDataPoints: true,
                groupName: this.groupName,
                showTooltip: this.showTooltip,
                getTooltip: function(d) {
                    const tooltip = _.template(/* template */ `                    
                    <div><%- date %></div>
                    <div><strong><%- count %></strong></div>
                    <div><%- name %> </div>
                    `);
                    return tooltip({
                        name: this.groupName,
                        count: CC.utils.friendly_number(d.data.value, 1, true, 1000, 'standard'),
                        date: d3.timeFormat('%B %e')(d.data.key)
                    });
                }
            })
                .height(this.height)
                .curve(d3.curveCardinal.tension(0.5))
                .renderHorizontalGridLines(true)
                .renderVerticalGridLines(true)
                .controlsUseVisibility(false)
                .x(d3.scaleTime())
                .xUnits(d3.timeDays)
                .renderDataPoints({
                    radius: 3
                })
                .yAxisLabel('Total Count')
                .xAxisLabel(this.month)
                .yAxisPadding('15%')
                .xAxisPadding(1)
                .elasticX(true)
                .elasticY(true)
                .ordinalColors([this.convertColorVariableToHex('--line-chart-color')])
                .title(() => undefined)
                .legend(
                    dc
                        .legend()
                        .x(width - 110)
                        .y(5)
                        .itemHeight(12)
                        .horizontal(true)
                        // TOMA-2524: Don't set autoItemWidth or it breaks in Firefox
                        .itemWidth(100)
                        .autoItemWidth(false)
                )
                .margins({
                    top: 45,
                    right: 20,
                    bottom: 50,
                    left: 80
                });

            chart
                .yAxis()
                .tickFormat(function(v) {
                    return CC.utils.friendly_number(v, 1, true, 100000, 'tiny');
                })
                .ticks(3);

            chart
                .xAxis()
                .ticks(d3.timeDay, 1)
                .tickFormat(data => {
                    // Workaround the xAxisPadding on the left side of the graph
                    const tickDate = moment(data);
                    const endDate = moment(lastEntry[0].get('dataDate'), 'YYYYMMDD');

                    if (tickDate.isAfter(endDate, 'day')) {
                        return '';
                    }
                    return d3.timeFormat('%e')(data);
                });

            chart.on('preRedraw', () => {
                if (this.$el) {
                    // Reset the fill helper
                    this.$('.trend-chart-main').addClass('initial-fill');
                }
            });

            chart.on('renderlet', () => {
                if (this.$el) {
                    const gradientId = 'data-in__tag-events';
                    const fill = `url(#${[gradientId]})`;
                    if (this.$('.area').attr('fill') == fill) {
                        return;
                    }

                    // Add a gradient under the line chart
                    const color = this.convertColorVariableToHex('--line-chart-color');
                    const lg = chart
                        .svg()
                        .selectAll('defs')
                        .append('linearGradient')
                        .attr('id', gradientId)
                        .attr('x1', '0%')
                        .attr('x2', '0%')
                        .attr('y1', '0%')
                        .attr('y2', '100%');
                    lg.append('stop')
                        .attr('offset', '0%')
                        .style('stop-color', color)
                        .style('stop-opacity', 0.2);
                    lg.append('stop')
                        .attr('offset', '30%')
                        .style('stop-color', color)
                        .style('stop-opacity', 0.1);
                    lg.append('stop')
                        .attr('offset', '100%')
                        .style('stop-color', color)
                        .style('stop-opacity', 0);

                    this.$('.area').attr('fill', fill);

                    // Remove the temporary class that prevents the area from being rendered
                    // solidly until this renderlet can be run
                    this.$('.trend-chart-main').removeClass('initial-fill');
                }
            });
        },

        renderSparkLine: function(xFilter) {
            const parseTime = d3.timeParse('%Y%m%d');
            const dimension = xFilter.dimension(d => {
                return parseTime(d.get('dataDate'));
            });
            const group = dimension.group().reduceSum(d => {
                return +d.get(this.field);
            });

            this.buildLineChart(`.${this.name}-filter`, dimension, group, {
                renderArea: false,
                renderDataPoints: false,
                chartClass: 'sparkline',
                showTooltip: this.showTooltip,
                getTooltip: function(d) {
                    const tooltip = _.template(/* template */ `                    
                    <div><%- date %></div>
                    <div><strong><%- count %></strong></div>
                    <div><%- name %> </div>
                    `);
                    return tooltip({
                        name: this.groupName,
                        count: CC.utils.friendly_number(d.data.value, 1, true, 1000, 'standard'),
                        date: d3.timeFormat('%B %e')(d.data.key)
                    });
                }
            })
                .height(this.sparkLineHeight)
                .curve(d3.curveCardinal.tension(0.5))
                .xyTipsOn(this.showTooltip)
                .clipPadding(15)
                .colors('#FFFFFF') // Turn it all white until the custom pattern shows up in renderlet
                .yAxisPadding(0)
                .dotRadius(2)
                .title(() => {})
                .margins({ left: 5, top: 5, right: 5, bottom: 5 });
        }
    });

    return TrendChartView;
});
