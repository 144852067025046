define([
    'models/app/BasicModel',
    'CC',
    'moment'
],
function(
    BasicModel,
    CC,
    moment
) {
    var lastRunModel = BasicModel.extend({
        url: CC.apiUrl + '/statistics/lastRun',

        getTime: function() {
            return moment.utc(this.get('millis')).format('MMMM Do YYYY');
        },

        getDateObj: function() {
            // Have to be careful with Timezone here - use default locale and it should all work out
            return new Date(this.get('year'), this.get('monthOfYear') - 1, this.get('dayOfMonth'));
        } 
    });
  
    return lastRunModel;
});
